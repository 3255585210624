<template>
    <div class="technical_theme">
      <div id="showRelationChart" v-if="themeList.length && !loadingTheme">
      </div>
       <el-table
          :data="themeList"
          height="30.7vh"
          stripe
          class="tec_theme"
          v-if="themeList.length && !loadingTheme"
          style="width: 100%">
          <el-table-column
            type="index"
            label="序号"
            header-align="center"
            align="center"
            width="180">
          </el-table-column>
          <el-table-column
            label="技术主题"
            header-align="left"
            align="left"
            width="250">
            <template slot-scope="scope">
                <el-tooltip :content="scope.row.name" placement="bottom" effect="light">
                  <span style="white-space: nowrap;">{{scope.row.name}}</span>
                </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="高频关键词"
            header-align="left"
            align="left">
            <template slot-scope="scope">
                <el-tooltip :content="scope.row.keywords.join(', ')" placement="right" effect="light">
                  <span style="white-space: nowrap;">{{scope.row.keywords.join(", ")}}</span>
                </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="count"
            header-align="center"
            align="center"
            label="主题热度"
            width="180">
          </el-table-column>
      </el-table>
      <div v-if="!themeList.length && loadingTheme" v-loading="!themeList.length && loadingTheme" style="width:100px;height:100px;margin: 0 auto;margin-top: 200px;"></div>
      <no-data v-if="!themeList.length && !loadingTheme" text="暂无相关内容" iconSty="margin-top:200px;"></no-data>
      <el-dialog :title="xiazuanName" :visible.sync="dialogTableVisible" class="xiazuan_dialog">
        <el-table :data="xiazuanData" height="500" stripe class="dialog_table" v-loading="loadingXiazuan">
          <el-table-column type="index" label="序号" width="60" header-align="center" align="center"></el-table-column>
          <el-table-column label="标题" width="200" header-align="center" align="center">
            <template slot-scope="scope">
              <el-popover
                placement="left"
                trigger="click"
                :content="scope.row[0]"
                >
                <span slot="reference" style="white-space: nowrap;" class="active_span">{{scope.row[0]}}</span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="摘要" header-align="center" align="center">
            <template slot-scope="scope">
              <el-popover
                placement="left"
                trigger="click"
                :content="scope.row[1]"
                >
                <span slot="reference" style="white-space: nowrap;" class="active_span">{{scope.row[1]}}</span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="关键词" header-align="center" align="center">
            <template slot-scope="scope">
                <el-popover
                placement="left"
                trigger="click"
                :content="scope.row[2]"
                >
                <span slot="reference" style="white-space: nowrap;" class="active_span">{{scope.row[2]}}</span>
              </el-popover>
            </template>
          </el-table-column>

        </el-table>
    </el-dialog>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import noData from "../components/noData.vue";

export default {
  components: {
    noData,
  },
  inject:['reload'],
  data () {
    return {
      dialogTableVisible: false,
      themeList: [],
      xiazuanName: "",
      xiazuanData: [],
      loadingTheme: true,
      loadingXiazuan: true,
    }
  },
  watch: {
     // 利用watch方法检测路由变化：
    '$route': function (to, from) {
      if(to.query.id != from.query.id){
        this.reload()
      }
    }
  },
  created () {
    this.getThemeListData()
  },
  mounted () {
  },
  methods: {
    // 技术主题列表获取
    getThemeListData () {
      this.loadingTheme = true
      this.$http({
        method: "GET",
        url: "/hottopic/front_list/",
        params: {
          taskid: this.$route.query.id
        }
      }).then(res => {
        if (res.data.code === 0) {
          this.themeList = res.data.data
          this.loadingTheme = false
          this.$nextTick(()=>{
            this.getHotEmerging()
          })
        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error));
    },
    // 热点技术图谱
    getHotEmerging () {
      this.$http({
        method: "GET",
        url: "/hottopic/atlas/",
        params: {
          taskid: this.$route.query.id
        }
      }).then(res => {
        if (res.data.code === 0) {
            this.showRelationChart(res.data)
        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error));
    },

    // 技术主题识别与技术图谱网络 下钻
    getDetailData (id) {
      this.loadingXiazuan = true
      this.$http({
        method: "POST",
        url: "/topic/post/list/",
        params: {
          taskid: this.$route.query.id,
          topicSort: id,
          fields: 'title,abstract,author_keyword',
          pageSize: -1
        }
      }).then(res => {
        if (res.data.code === 0) {
         this.xiazuanData = res.data.data
         this.loadingXiazuan = false
        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error));
    },

    showRelationChart(data) {
      echarts.init(document.getElementById('showRelationChart')).dispose()
      let myChart = echarts.init(document.getElementById("showRelationChart"));
      // window.onresize = echarts.init(document.getElementById("showRelationChart")).resize
      var option;
      option = {
        // tooltip: {},
        // tooltip: {
        //   trigger: "item",
        //   formatter: function(params){
        //     console.log(params)
        //   }
        // },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //     }
        //   },
        //   top: '50px',
        //   right: '30px'

        // },
        legend: [
          {
            data: data.categories.map(function (a) {
              return a.name;
            })
          }
        ],
        color: ["#A8CEA9","#B14646","#F2E224","#FFC1C1","#70B472","#E68295","#FC8452","#FFB393","#339ADB", "#FFC84B","#FFE09A", "#D98686","#E1EF76","#C2F0A9","#3BA272","#EE6666","#E5E5E5","#DFEDE0","#33B2B0","#86CCCB","#B8DEDE","#EB9D09","#73C0DE", "#B0DDEF","#5470C6","#96A7DE","#C5CDEF","#E2E2FC","#6764BA","#9A60B4","#BF9EDE","#EA7CCC"],
        series: [
          {
            // name: 'Les Miserables11',
            type: 'graph',
            // layout: 'none',
            layout: 'force',
            data: data.nodes,
            links: data.links,
            categories: data.categories,
            roam: true,
            focusNodeAdjacency: false,
            itemStyle: {
              normal: {
                  borderColor: '#fff',
                  borderWidth: 1,
                  shadowBlur: 10,
                  shadowColor: 'rgba(0, 0, 0, 0.3)'
              }
            },
            label: {
              show: true,
              position: 'right',
              formatter: '{b}'
            },
            labelLayout: {
              hideOverlap: true
            },
            scaleLimit: {
              min: 0.4,
              max: 6
            },
            lineStyle: {
              color: 'target',
              opacity: 0.6,
              curveness: 0.3
            },
            emphasis: {
                lineStyle: {
                    width: 10
                }
            },
            force: {
                layoutAnimation: false,
                repulsion: 500
            },
            // lineStyle: {
            //   color: 'source',
            //   curveness: 0.3
            // }
          }
        ]
      };
      document.getElementById("showRelationChart").removeAttribute('_echarts_instance_');
      let that = this;
      myChart.on('click', function (param) {
        that.getDetailData(param.data.id)
        that.dialogTableVisible = true
        that.xiazuanName = param.data.name
      });
      option && myChart.setOption(option);
      // return myChart;
    }
  }

}
</script>
<style lang="scss" scoped>
@import "../assets/scss/mixin.scss";
  .technical_theme{
  }
  #showRelationChart{
    width: 100%;
    // height: 450px;
    height: 47.5vh;
  }
  .el-table.tec_theme{
    /deep/.el-table__body-wrapper{
      .el-table__body{
        width: 100% !important;
      }
    }
  }
  .active_span{
    cursor: pointer;
    &:focus,&:active,&:hover{
      color: #3a8ee6;
    }
  }
  .xiazuan_dialog{
    /deep/.el-dialog__body{
        padding: 10px;
      }
    /deep/.el-dialog__header{
      padding: 13px;
      background-color: #FAFAFA;

      .el-dialog__title{
        color: $black666;
        font-size: 14px;
        line-height: 14px;
      }
      .el-dialog__headerbtn{
        top: 13px;
      }
    }

  }
  .el-table.dialog_table{
    /deep/.el-table__body-wrapper{
      .el-table__body{
        width: 100% !important;
      }
    }
  }
</style>
