<style lang="scss" scoped>
@import "../assets/scss/mixin.scss";
  .fa_container{
    width: 100%;
    height: 100vh;
  }
  .el-main{
    position: relative;
    padding: 0;
    padding: 40px 49px 0;
    background-color: $white;
    color: $black666;
    overflow-y: auto;
  }
  .el-header{
    padding: 0;
    // @media (max-width: 1440px) and (max-height:900px) {
    //   height: 58px !important;
    // }
  }
  .el-footer{
    padding: 0;
  }

  // 左侧menu
  .aside_left{
    overflow-y: auto;
    flex-shrink: 0;
    width: 247px;
    background-color: $white;
    &::-webkit-scrollbar {display:none}
    .el-menu{
      border-right: none;
      width: 100%;
      .el-menu-item{
        span{
          height: unset;
          width: unset;
          overflow: visible;
          visibility: visible;
          display: inline;
        }
      }
    }
  }
  .title{
    height: 36px;
    line-height: 36px;
    text-align: left;
    background-color: #E6E9F2;
    border-left: 10px solid $middleblue;
    padding-left: 32px !important;
    margin-top:40px;
    span{
      font-size: 18px;
      color:$middleblue !important;
      font-weight: bold;
    }
    &::before{
        content: '';
        position: absolute;
        left: 4px;
        top: 0;
        width: 4px;
        height: 100%;
        background-color: $middleblue;
      }
  }
  .subtitle{
    height: 36px;
    line-height: 36px;
    text-align: left;
    padding-left: 42px !important;
    border-bottom: 1px solid #E6E9F2;
    span{
      font-size: 14px;
      color: $black666;
    }
    &:focus,&:hover,&.is-active{
      background-color: inherit;
      font-weight: bold;
      span{
        color:$darkblue !important;
      }
      &::before{
        content: '';
        position: absolute;
        left: 18px;
        top: 50%;
        width: 10px;
        height: 10px;
        background: url("../assets/images/circle.png") no-repeat center center/100% 100%;
        transform: translate(0, -50%);
      }
    }
  }
</style>
<template>
 <div class="menu_box">
   <el-container class="fa_container">
      <el-header height="80px">
          <Header :ifShowSearch="true"></Header>
      </el-header>
      <el-container style="height:100%">
        <!-- 左侧导航 -->
        <aside class="aside_left">
          <el-menu
            :default-active="$route.path"
            mode='vertical'
            router
            @select="handleSelect"
            ref="menu"
          >
              <el-menu-item class="title">
                <span>技术热点和前沿</span>
              </el-menu-item>
              <el-menu-item  index="/home/technical_theme" :route="'/home/technical_theme?id=' + $route.query.id" class="subtitle">
                <span>技术主题识别与技术图谱网络</span>
              </el-menu-item>
              <el-menu-item  index="/home/technical_expert" :route="'/home/technical_expert?id=' + $route.query.id" class="subtitle">
                <span>技术与专家/机构网络</span>
              </el-menu-item>
              <el-menu-item  index="/home/technical_hot" :route="'/home/technical_hot?id=' + $route.query.id" class="subtitle">
                <span>技术热度动态曲线</span>
              </el-menu-item>
              <!-- <el-menu-item class="title">
                <span>新兴技术</span>
              </el-menu-item> -->
              <el-menu-item  index="/home/emerging_technology" :route="'/home/emerging_technology?id=' + $route.query.id" class="subtitle">
                <span>新兴技术识别</span>
              </el-menu-item>
              <el-menu-item class="title">
                <span>技术发展趋势</span>
              </el-menu-item>
              <el-menu-item  index="/home/technical_forcast" :route="'/home/technical_forcast?id=' + $route.query.id" class="subtitle">
                <span>新兴技术预测</span>
              </el-menu-item>
              <el-menu-item  index="/home/technical_growth" :route="'/home/technical_growth?id=' + $route.query.id" class="subtitle">
                <span>技术成长周期曲线</span>
              </el-menu-item>
          </el-menu>
        </aside>
        <el-main>
            <router-view></router-view>
        </el-main>
      </el-container>
      <el-footer height="30px">
          <copyright></copyright>
      </el-footer>
   </el-container>
  </div>
</template>
<script>
// import $ from 'jquery'
import Header from '../components/Header.vue'
import copyright from '../components/copyright.vue'
export default {
  name: 'Home',
  props: {

  },
  components: {
    Header,
    copyright
  },
  data () {
    return {
    }
  },
  mounted (){
  },
  methods: {
    handleSelect(index, indexPath) {
        // console.log(index)
        // console.log(indexPath)
        // this.$router.push({
        //   path: index,
        //   // query: {
        //   //   tag: 'areaSearch',
        //   //   normalSearchValue: name
        //   //   }
        // })
    },
  },
  computed: {}
}
</script>

