import * as echarts from 'echarts';
import { formatDecimal } from '../../plugins/common'


// 技术热度动态曲线图
export function showHotCharts(el, xDada,data,type) {
  echarts.init(document.getElementById(el)).dispose()
  let myChart = echarts.init(document.getElementById(el), null, { devicePixelRatio: 2 })
  window.onresize = echarts.init(document.getElementById(el)).resize


   let color = ["#B14646","#FC8452", "#FFC84B","#F2E224","#E1EF76","#3BA272","#33B2B0","#339ADB","#9A60B4","#EA7CCC"]
      var seriesData = []
      for (let i = 0; i < data.length; i++) {
        seriesData.push({
          data: type? data[i].data: data[i].count,
          name: data[i].name,
          type: "line",
          smooth: true,
          symbol: 'circle', // 设置标记的图形为circle
          symbolSize: 8,
          z: 100,
          itemStyle: {
            emphasis:{ // 鼠标经过时
              color: '#fff',
              borderColor: color[i],
              borderWidth: 3, // 原点边框大小
            }
          },
        })
      }

      var option = {
        color: color,
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: seriesData.map(a => a.name),
          bottom: 0
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xDada,
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
          },
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#D1D1D1',
              type: 'dotted'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: "#999"
            },
          }
        },
        tooltip: {
          show: true,
          confine: true,
          trigger: "axis",
          triggerOn: 'mousemove',
          extraCssText:'border: 1px solid #FAFAFA;box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);padding:8px 10px;border-raidus:2px;',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            lineStyle: {
              type: 'solid'
            }
          },
          formatter(value) {
            // console.log(value)
            let html = '<div style="text-align:left;font-size:12px;color:#999999">'+value[0].name+'年'+'</div>'
            value.forEach((item,i)=>{
              html += '<p style="font-size:14px;"><p style="color:#666666;text-align:left"><span style="color:' + item.color + '">● </span>'+item.seriesName+ (type ? ' 技术成熟度：': ' 技术热度：') +'<span style="color:#333;font-weight:bold;">'+ (type ? formatDecimal(item.value,4): item.value) +'</span></p></p>'
            })
            return html
          }
        },
        series: seriesData
      };
  option && myChart.setOption(option);
  return myChart;
}
