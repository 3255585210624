<template>
  <div class="index_page">
    <Header></Header>
    <div class="search_case">
      <div class="center">
        <el-select v-model="value" clearable placeholder="请选择" class="select_case select_sty" @change="searchCase" popper-class="index_popper" @visible-change="caseVisible" ref="select_case">
          <el-option
            v-for="item in listOptions"
            :key="item.label"
            :label="item.label"
            :value="item.value">
              <!-- <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">示例</span> -->
          </el-option>
        </el-select>
        <div class="center_bottom">
          <div class="item f24" @click="goSearchCase(item.id)" v-for="(item,i) in taskBase" :key="i">
            {{item.name}}
          </div>
        </div>
      </div>
    </div>
    <copyright></copyright>
  </div>
</template>
<script>
import Header from '../components/Header.vue'
import copyright from '../components/copyright.vue'
export default {
  name: 'Index',
  data() {
    return {
       listOptions: [],
       taskBase: [],
       value: '',
    }
  },
  components: {
    Header,
    copyright
  },
  created () {
    this.getTaskList()
    this.getTaskBase()
  },
  methods:{
    // 下拉选 任务列表数据
    getTaskList () {
      this.$http({
        method: "GET",
        url: "/task/list/",
        params: {
          base: true,
          finish: true,
          status: 0
        }
      }).then(res => {
        if (res.data.code === 0) {
          res.data.data.forEach((item, i)=>{
            let obj = {}
            obj.value = item.taskid
            obj.label =item.name
            this.listOptions.push(obj)
          })
        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error));
    },

    // 首页六个基础项目数据
    getTaskBase () {
      this.$http({
        method: "GET",
        url: "/task/base/",
      }).then(res => {
        if (res.data.code === 0) {
          this.taskBase = res.data.data
        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error));
    },

    //跳转至home正页
    goSearchCase (id) {
      this.$router.push({
        path: '/home',
        query: {
          id: id
        }
      });
    },

    caseVisible (e) {
      e == false ? this.$refs.select_case.blur() :''
    },
    searchCase (e) {
      console.log(e)
      this.$router.push({
        path: '/home',
        query: {
          id: e
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/scss/mixin.scss";
  .index_page{
    width: 100%;
    height: 100vh;
    .search_case{
      width: 100%;
      height: calc(100vh - 80px - 30px);
      background: url('../assets/images/login_bg.png') no-repeat center top/100% 100%;
      .center{
        margin: 0 auto;
        padding-top: 22vh;
        text-align: center;
        .select_case{
          width: 37%;
          /deep/.el-input__inner{
            height: 60px;
            font-size: 18px;
            padding: 0 46px 0 30px;
            @include text-ellipsis(100%)
          }
          /deep/.el-select__caret{
            font-size: 18px;
            margin-right: 20px;
          }
        }
        .center_bottom{
          width: 53%;
          margin: 16vh auto 0 auto;
          color: $white;
          @include flex-align-justify(center,space-between);
          flex-wrap: wrap;
          .item{
            width: 30%;
            text-align: left;
            border-bottom: 1px solid $white;
            cursor: pointer;
            font-weight: lighter;
            margin-bottom: 8vh;
            padding-bottom: 15px;
            &:hover{
              border: none;
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss">
.index_popper{
  max-width: 37%;
  .el-select-dropdown__item {
    font-size: 18px;
    padding-left: 30px;
    height: 40px;
    line-height: 40px;
  }
}
</style>
