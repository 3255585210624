<template>
  <div class="technical_forcast">
        <el-table
          :data="forcastData"
          stripe
          class="tec_theme"
          height="80vh"
          v-loading="loadingForcast"
          width="width: 100%">
          <el-table-column
            type="index"
            label="序号"
            header-align="center"
            align="center"
            width="180">
          </el-table-column>
          <el-table-column
            label="可能产生交叉融合的技术主题对"
            header-align="center"
            align="center">
            <template slot-scope="scope">
              <div style="display:flex;align-items:center;justify-content:center">
                <span class="left">{{ scope.row.topic_a.name }}</span>
                <i class="iconfont icon-closel"></i>
                <span class="right">{{ scope.row.topic_b.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="sim"
            header-align="center"
            align="center"
            width="300">
            <template slot-scope="scope">
              {{formatDecimal(scope.row.sim,4)}}
            </template>
            <template slot="header" slot-scope="{}">
              <div class="center_sty">
                <span>交叉融合可能性</span>
                <el-tooltip content="两两技术主题交叉融合促进新技术和新发现形成的概率。在技术热度图谱中，概率值大小受两两技术主题节点对共同邻居数量及节点度的影响。" placement="bottom" effect="light">
                  <i class="iconfont icon-wenhao"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
  </div>
</template>
<script>
export default {
  data () {
    return {
      activeName: 'first',
      forcastData: [],
      loadingForcast: false
    }
  },
  created () {
    this.getTechnicalForcast()
  },
  methods: {
    // 小数点保留
    formatDecimal(num, decimal) {
        num = num.toString()
        let index = num.indexOf('.')
        if (index !== -1) {
            num = num.substring(0, decimal + index + 1)
        } else {
            num = num.substring(0)
        }
        return parseFloat(num).toFixed(decimal)
    },
    // 新兴技术预测 数据
    getTechnicalForcast () {
      this.loadingForcast = true
      this.$http({
        method: "GET",
        url: "/emerging_tech_topic/linkpred/",
        params: {
          taskid: this.$route.query.id
        }
      }).then(res => {
        if (res.data.code === 0) {
          this.forcastData = res.data.data
          this.loadingForcast = false
        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error));
    },
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/scss/mixin.scss";
  .el-table.tec_theme{
    /deep/.cell{
      .left{
        display: inline-block;
        width: 43%;
        text-align: right;
      }
      .icon-closel{
        color: #C6C6C6;
        font-size: 12px;
        margin: 0 30px;
      }
      .right{
        display: inline-block;
        width: 43%;
        text-align: left;
      }
    }
    /deep/.el-table__body-wrapper{
      .el-table__body{
        width: 100% !important;
      }
    }
  }
</style>
