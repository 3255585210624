<template>
  <div class="technical_hot">
    <div class="top_choose">
      <div class="tags">
        <el-tag
          v-for="tag in seriesData"
          :key="tag.name"
          :closable="seriesData.length == 1 ?false:true"
          effect="plain"
          type="info"
          @close="handleClose(tag)"
          >
          {{tag.name}}
        </el-tag>
      </div>

      <el-autocomplete
        popper-class="search-autocpmplete"
        v-model="themeAdd"
        size="small"
        :fetch-suggestions="querySearch"
        placeholder="搜索主题，添加对比"
        :trigger-on-focus="false"
        @select="handleSelect"
        suffix-icon="el-icon-search"
        >
        </el-autocomplete>
    </div>
    <div id="hotCharts" style="width: 100%;height: 300px;"></div>
  </div>
</template>
<script>
import { showHotCharts } from '@/assets/js/chart.js'
export default {
  data () {
    return {
      themeAdd: '',
      xDada: [],
      seriesData: [],
    }
  },
  created () {
    this.getGrowthList()
  },
  methods:{
    //主题列表获取
    getGrowthList () {
      this.$http({
        method: "GET",
        url: "/topic/list/",
        params: {
          taskid: this.$route.query.id
        }
      }).then(res => {
        if (res.data.code === 0) {
          this.getHotTopicData(res.data.data[0].id)
        }else{
            this.$message({showClose: true,message: res.data.msg,duration: 0,type: 'error'})
        }
      }).catch(error => console.log(error));
    },

    // 模糊匹配选中
    handleSelect (v) {
      if(this.seriesData.length > 9){
        this.$message({
          showClose: true,
          message: '最多添加10个主题对比',
          duration: 3000,
          type: 'error'
        })

        return
      }

      let flag = true
      this.seriesData.forEach((item)=>{
        item.id == v.id ? flag = false : ''
      })
      if(!flag){

        this.$message({
          showClose: true,
          message: '您已添加过该主题',
          duration: 3000,
          type: 'error'
        })

        return
      }

      this.getHotTopicData(v.id)
    },

    // 某个主题删除
    handleClose(tag) {
      console.log(tag)
      this.seriesData.splice(this.seriesData.indexOf(tag), 1);
      showHotCharts('hotCharts',this.xDada,this.seriesData,0)
    },

    querySearch(queryString, cb) {
        if (queryString) {
          this.addCompetitor(queryString, cb)
        }
    },

    addCompetitor (queryString, cb) {
      this.$http({
          method: 'GET',
          url: '/topic/suggest/',
          params: {
            taskid: this.$route.query.id,
            text: queryString
          }
      }).then(function (res) {
          res.data.data.map(item=>{
            item.value = item.name
          })
          cb(res.data.data)
      });
    },

    //主题文章数折线图
    getHotTopicData (topicid) {
      this.$http({
        method: "GET",
        url: "/hottopic/river/",
        params: {
          taskid: this.$route.query.id,
          topicIds: topicid
        }
      }).then(res => {
        if (res.data.code === 0) {
          this.xDada =  res.data.legend
          this.seriesData.push(res.data.data[0])
          showHotCharts('hotCharts',this.xDada,this.seriesData,0)
        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error));
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/scss/mixin.scss";
  .technical_hot{
    width: 1000px;
    margin: 70px auto 0 auto;
    // text-align: right;
  }
  .el-tag {
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .top_choose{
    @include flex-align-justify(center,space-between);
    .tags{
      width: 53%;
      text-align: left;
    }
    .el-autocomplete{
      width: 40%;
      /deep/.el-input__inner:focus{
        border-color: #C0C4CC;
      }
    }
  }
</style>
