<template>
  <div class="header">
    <span class="title f20 bold">技术主题识别和发展预测</span>
    <div class="right">
      <div class="right_left" v-if="ifShowSearch">
        <span class="back_index mr110" @click="backIndex">← 首页</span>
        <el-select v-model="value" placeholder="请选择" class="select_case select_sty mr110" @change="searchCase" popper-class="header_popper" @visible-change="caseVisible" ref="select_case">
          <el-option
            v-for="item in listOptions"
            :key="item.label"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-dropdown trigger="click" @command="handleCommand" placement="bottom" popper-class="user_popper">
        <span class="el-dropdown-link user_info">
          <span class="user_name">{{user_name}}</span><i class="el-icon-caret-bottom"></i>
        </span>
        <el-dropdown-menu slot="dropdown" class="user_dropdown">
          <el-dropdown-item command="a" v-if="permisson.length>1?true:false">进入后台</el-dropdown-item>
          <el-dropdown-item command="b">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { delCookie } from '../assets/js/common'
export default {
  name: 'Header',
  props: {
    ifShowSearch: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
       listOptions: [],
       value: '',
       permisson: '',
       user_name:'',
    }
  },
  mounted () {
    this.user_name = localStorage.getItem('user')
    this.getUserPermisson()
    if(this.ifShowSearch) {
      this.getTaskList()
    }
  },
  methods:{
    // 下拉选 任务列表数据
    getTaskList () {
      this.$http({
        method: "GET",
        url: "/task/list/",
        params: {
          base: true,
          finish: true,
          status: 0
        }
      }).then(res => {
        if (res.data.code === 0) {
          res.data.data.forEach((item, i)=>{
            let obj = {}
            obj.value = item.taskid
            obj.label =item.name

            this.listOptions.push(obj)

            if(item.taskid == this.$route.query.id) { //#####(彦斌修改了数据之后就不用加这个判断了)如果数组里没有对应的taskid，那么下拉框默认显示请选择
              this.value = this.$route.query.id
            }

          })
        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error));
    },
    backIndex () {
      this.$router.push({
        path: '/',
      })
    },
    caseVisible (e) {
      e == false ? this.$refs.select_case.blur() :''
    },
    searchCase (e) {
      console.log(e)
      this.$router.push({
        path: '/home',
        query: {
          id: e
        }
      });
    },
    handleCommand(command) {
      if(command === 'a') {
        window.open('https://backend.techtopic.maixunbytes.com/index/?open_id=base-left-index', '_blank');
      }
      if(command === 'b') {
        this.logout()
      }
    },
     logout() {
      this.$http({
        method: "POST",
        url: "/account/logout/"
      }).then(res => {
        if (res.data.code === 0) {
            localStorage.removeItem('Authorization')
            localStorage.removeItem('user')
            localStorage.removeItem('permisson')
            delCookie("Authorization", '.maixunbytes.com')
            delCookie("Name", '.maixunbytes.com')
            this.$router.push("/login");
        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error + " in logout"));
    },
    getUserPermisson(){
      this.$http({
        method: "GET",
        url: "/pv/list/",
        params: {
          type: 1
        }
      }).then(res => {
        if (res.data.code === 0) {
          this.permisson = res.data.data
        }else{
          this.$message({
            showClose: true,
            message: res.data.msg,
            duration: 0,
            type: 'error'
          })
        }
      }).catch(error => console.log(error));
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/scss/mixin.scss";
  .header{
    width: 100%;
    height: 80px;
    background: $darkblue;
    color: $white;
    padding: 0 86px 0 63px;
    @include flex-align-justify(center,space-between);
    .title{
      @include letter-spacing
    }
    .right{
      .back_index{
        cursor: pointer;
      }
      .right_left{
        display: inline-block;
      }
      .select_case{
        width: 375px;
        /deep/.el-input__inner{
          height: 40px;
          font-size: 14px;
          @include text-ellipsis(100%)
        }
        /deep/.el-select__caret{
          font-size: 14px;
        }
      }
      .user_info{
        color: $white;
        cursor: pointer;
      }
    }
    .mr110{
      margin-right: 110px;
    }
  }
</style>
<style lang="scss">
@import "../assets/scss/mixin.scss";
.header_popper{
  max-width: 375px;
  .el-select-dropdown__item {
    font-size: 14px;
    padding-left: 15px;
    height: 36px;
    line-height: 36px;
  }
}
.user_dropdown{
  margin-top: 33px !important;
  padding: 0;
  border: 1px solid #E6E9F2;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
  border-radius: 0;
  .el-dropdown-menu__item{
    color: $black666;
    padding: 0 42px;
    white-space: nowrap;
    &:hover,&:focus{
      color: $darkblue;
    }
  }
  .popper__arrow{
    display: none;
  }
}
</style>
