<template>
  <div>
      <el-autocomplete
        popper-class="search-autocpmplete"
        v-model="themeSearch"
        size="small"
        :fetch-suggestions="querySearch"
        placeholder="搜索主题名称，查看优势作者和优势机构"
        :trigger-on-focus="false"
        @select="handleSelect"
        suffix-icon="el-icon-search"
        >
        </el-autocomplete>
      <div class="technical_expert">
          <div class="top">
            <div class="title f16">优 势 作 者</div>
            <div class="excellentExpert" id="excellentExpert"></div>
          </div>
          <div class="bottom">
            <div class="title f16">优 势 机 构</div>
            <div class="excellentOrganization" id="excellentOrganization"></div>
          </div>
          <!-- 如果需要导航按钮 -->
          <div :class="['swiper-button-prev', (authorOrganData.has_last ? '':'swiper-button-disabled')]" @click="prevBtn"></div>
          <div :class="['swiper-button-next',(authorOrganData.has_next ? '':'swiper-button-disabled')]" @click="nextBtn"></div>
      </div>
      <el-dialog :title="xiazuanName" :visible.sync="dialogTableVisible" class="xiazuan_dialog">
        <el-table :data="xiazuanData" height="500" stripe class="dialog_table"  v-loading="loadingXiazuan">
          <el-table-column type="index" label="序号" width="60" header-align="center" align="center"></el-table-column>
          <el-table-column label="标题" width="200" header-align="center" align="center">
            <template slot-scope="scope">
              <el-popover
                placement="left"
                trigger="click"
                :content="scope.row[0]"
                >
                <span slot="reference" style="white-space: nowrap;" class="active_span">{{scope.row[0]}}</span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="摘要" header-align="center" align="center">
            <template slot-scope="scope">
              <el-popover
                placement="left"
                trigger="click"
                :content="scope.row[1]"
                >
                <span slot="reference" style="white-space: nowrap;" class="active_span">{{scope.row[1]}}</span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="关键词" header-align="center" align="center">
            <template slot-scope="scope">
                <el-popover
                placement="left"
                trigger="click"
                :content="scope.row[2]"
                >
                <span slot="reference" style="white-space: nowrap;" class="active_span">{{scope.row[2]}}</span>
              </el-popover>
            </template>
          </el-table-column>

        </el-table>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from 'echarts';
import "swiper/dist/css/swiper.css";
export default {
  data () {
    return {
      pageSize: 10, //每页数量
      pageNo: 1, //第几页
      authorOrganData: {},
      dialogTableVisible: false,
      xiazuanName: "",
      xiazuanData: [],
      loadingXiazuan: false,
      themeSearch: '', // 主题名
    }
  },
  created () {
    this.getAuthorOrgan()
  },
  methods: {
    querySearch(queryString, cb) {
        if (queryString) {
          this.addCompetitor(queryString, cb)
        }
    },

    addCompetitor (queryString, cb) {
      this.$http({
          method: 'GET',
          url: '/topic/suggest/',
          params: {
            taskid: this.$route.query.id,
            text: queryString
          }
      }).then(function (res) {
          res.data.data.map(item=>{
            item.value = item.name
          })
          cb(res.data.data)
      });
    },
    handleSelect (v) {
      this.getAuthorOrgan(v.id,true)
    },
    getAuthorOrgan (id,isSearch) {
      this.$http({
        method: "GET",
        url: "/hottopic/author_and_organ/",
        params: {
          taskid: this.$route.query.id,
          topicIds: id,
          pageSize: this.pageSize,
          pageNo: this.pageNo
        }
      }).then(res => {
        if (res.data.code === 0) {
          this.authorOrganData = res.data

          this.showExcellentExpert('excellentExpert',res.data.author,isSearch,'author')
          this.showExcellentExpert('excellentOrganization',res.data.organ,isSearch,'organ')

        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error));
    },
    // 技术与专家/机构网络 下钻
    getDetailData (type,id,pid) {
      this.xiazuanData = []
      let obj = {
        taskid: this.$route.query.id,
        fields: 'title,abstract,author_keyword',
        topicid: pid,
        pageSize: -1
      }
      type == 'author'?obj.author = id: obj.organ = id
      this.loadingXiazuan = true
      this.$http({
        method: "POST",
        url: "/topic/post/list/",
        params:obj,
      }).then(res => {
        if (res.data.code === 0) {
         this.xiazuanData = res.data.data
         this.loadingXiazuan = false
        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error));
    },

    showExcellentExpert (el, data,isSearch,type) {
      echarts.init(document.getElementById(el)).dispose()
      let myChart = echarts.init(document.getElementById(el));
      window.onresize = echarts.init(document.getElementById(el)).resize
      var option;

      data.children.forEach(function (datum, index) {
        index % 2 === 0 && (datum.collapsed = true);
        if(isSearch){
          index == 0 && (datum.collapsed = false)
        }
      });
      myChart.setOption(
        (option = {
          tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove',
            // enterable:true,
            extraCssText: 'max-width: 300px;',
            formatter: "<div style='display:block;word-break: break-all;word-wrap: break-word;white-space:pre-wrap'>" + "{b0}&nbsp;&nbsp;&nbsp;{c0}" + "</div>",
          },
          series: [
            {
              type: 'tree',
              data: [data],
              top: '1%',
              left: '7%',
              bottom: '1%',
              right: '10%',
              symbolSize: 7,
              leaves: {
                label: {
                  position: 'right',
                  verticalAlign: 'middle',
                  align: 'left'
                }
              },
              emphasis: {
                focus: 'descendant'
              },
              expandAndCollapse: true,
              animationDuration: 550,
              animationDurationUpdate: 750,
              // initialTreeDepth: -1,
              label: {
                  position: 'left',
                  verticalAlign: 'middle',
                  align: 'left',
                  fontSize: 9,
                  width: 130,
                  lineOverflow: 'truncate',
                  overflow: 'truncate',
                  formatter: ['{b}'].join('\n'),
              },
            }
          ]
        })
      );

      document.getElementById(el).removeAttribute('_echarts_instance_');
      let that = this;
      myChart.on('click', function (param) {
        if(param.treeAncestors.length == 4){
          let parent_id = ''
          data.children.forEach(function (datum, index) {
            if(datum.name == param.treeAncestors[2].name) {
              parent_id = datum.id
            }
          });

          that.getDetailData(type,param.data.name,parent_id)
          that.dialogTableVisible = true
          that.xiazuanName = param.data.name
        }
      });
      option && myChart.setOption(option);
    },
    prevBtn () {
      // $(".el-main").animate({ scrollTop: 0 }, 100);
      this.pageNo -= 1
      this.getAuthorOrgan()
    },
    nextBtn () {
      // $(".el-main").animate({ scrollTop: 0 }, 100);
      this.pageNo += 1
      this.getAuthorOrgan()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/scss/mixin.scss";
  .technical_expert{
    margin-bottom: 100px;
  }
  .top,.bottom{
    width: 94%;
    height: 650px;
    border: 1px solid #E6E9F3;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .title{
      -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: bt-rl;
      writing-mode: vertical-rl;
      color: $black333;
    }
  }
  .excellentExpert,.excellentOrganization{
    width: 78%;
    height: 100%;
  }
  .bottom{
    margin-top: 20px;
  }
  .swiper-button-next{
    right: 66px;
    background: url("../assets/images/swiper_r_d.png") no-repeat center center/100% 100%;
  }
  .swiper-button-prev{
    left: auto;
    background: url("../assets/images/swiper_l_d.png") no-repeat center center/100% 100%;
  }
  .swiper-button-next,.swiper-button-prev{
    position: fixed;
    width: 20px;
    height: 39px;

  }
  .xiazuan_dialog{
    /deep/.el-dialog__body{
        padding: 10px;
      }
    /deep/.el-dialog__header{
      padding: 13px;
      background-color: #FAFAFA;

      .el-dialog__title{
        color: $black666;
        font-size: 14px;
        line-height: 14px;
      }
      .el-dialog__headerbtn{
        top: 13px;
      }
    }
    .active_span{
      cursor: pointer;
      &:focus,&:active,&:hover{
        color: #3a8ee6;
      }
    }
  }
  .el-table.dialog_table{
    /deep/.el-table__body-wrapper{
      .el-table__body{
        width: 100% !important;
      }
    }
  }
  .el-autocomplete{
    width: 30%;
    margin-bottom: 20px;
    margin-left: 3%;
    /deep/.el-input__inner:focus{
      border-color: #C0C4CC;
    }
  }
</style>
<style lang="scss">
body{
  padding: 0 !important;
}
</style>
