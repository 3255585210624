import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './http'
import qs from 'qs'
import {
  autocomplete, Input, Button, Menu, Submenu, MenuItem, MenuItemGroup, Scrollbar, Table, TableColumn, Container, Header, Aside, Main, Footer, Row,
  Col, Dropdown, DropdownMenu, DropdownItem, Tabs, TabPane, DatePicker,Select, Option, Pagination, Checkbox, CheckboxButton, CheckboxGroup, Divider,
  Loading, Message, Backtop, Popover, Tooltip, Dialog,Tag} from 'element-ui'
import './assets/scss/common.scss'
import toastRegistry from './components/toast/index'
import '@/assets/iconfont/iconfont.css'
Vue.use(toastRegistry)


Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$qs = qs
Vue.prototype.$message = Message;

Vue.use(autocomplete)
Vue.use(Input)
Vue.use(Button)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Scrollbar)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Col)
Vue.use(Row)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(DatePicker)
Vue.use(Select)
Vue.use(Option)
Vue.use(Pagination)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Divider)
Vue.use(Backtop)
Vue.use(Loading)
Vue.use(Popover)
Vue.use(Tooltip)
Vue.use(Dialog)
Vue.use(Tag)
Vue.component(Message)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
