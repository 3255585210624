export function delCookie (name, domain) {
  var exp = new Date()
  exp.setTime(exp.getTime() - 1)
  var cval = getCookie(name)
  if (cval != null) document.cookie = name + '=' + encodeURI(cval) + ';expires=' + exp.toGMTString() + ';Domain=' + domain + ';Path=/'
}
export function getCookie(name) {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
    return decodeURI(arr[2]);
  else
    return null;
}
export function setCookie (name, value, domain) {
  var days = 30
  var exp = new Date()
  exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000)
  document.cookie = name + '=' + encodeURI(value) + ';expires=' + exp.toGMTString() + ';Domain=' + domain + ';Path=/'
}
