import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from '../http'
import store from '../store'

Vue.use(VueRouter)

import Home from '@/views/Home'
import Index from '@/views/Index'
import Login from '@/views/Login.vue'
import TechnicalTheme from '@/views/TechnicalTheme.vue' //技术主题识别与技术图谱网络
import TechnicalExpert from '@/views/TechnicalExpert.vue' //技术与专家/机构网络
import TechnicalHot from '@/views/TechnicalHot.vue' //技术热度动态曲线
import EmergingTechnology from '@/views/EmergingTechnology.vue' //基于文献的新兴技术识别
import TechnicalForcast from '@/views/TechnicalForcast.vue' //新兴技术预测
import TechnicalGrowth from '@/views/TechnicalGrowth.vue' //技术成长周期曲线

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
return routerPush.call(this, location).catch(error=> error)
}

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/home',
    component: Home,
    children: [
      {
        path: '',
        name: 'defaul',
        redirect: 'technical_theme',
      },
      {
          path: 'technical_theme',
          name: 'TechnicalTheme',
          component: TechnicalTheme
       },
       {
         path: 'technical_expert',
         name: 'TechnicalExpert',
         component: TechnicalExpert
       },
       {
         path: 'technical_hot',
         name: 'TechnicalHot',
         component: TechnicalHot
       },
       {
         path: 'emerging_technology',
         name: 'EmergingTechnology',
         component: EmergingTechnology
       },
       {
         path: 'technical_forcast',
         name: 'TechnicalForcast',
         component: TechnicalForcast
       },
       {
         path: 'technical_growth',
         name: 'TechnicalGrowth',
         component: TechnicalGrowth
       }
    ]
  },
//      { path: '*', component: NotFoundComponent } 加个notfound页，当页面报错就会跳转到该页
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
   if (to.path === '/login') {
    next();
  } else {
    let token = localStorage.getItem('Authorization');
    // console.log(token === null)
    if (token === null || token === '') {
      next('/login');
    } else {
      next();
    }
  }
  // if (JSON.stringify(store.state.user) === '{}') {
  //   axios.get('/accounts/get_user_login_status/').then(res => {
  //     if (res.data.status === 0) {
  //       store.commit('updateUser', res.data.user)
  //       return next({ path: '/' })
  //     } else {
  //       return next({ path: '/login' })
  //     }
  //   })
  // } else {
  //   return next()
  // }
  // next()
})
export default router
