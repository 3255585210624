<template>
  <div class="no_result">
      <div class="center_box">
        <img class="img_sty" src="../assets/images/no_data.png" alt="" :style="iconSty">
        <div class="text_sty" :style="textSty">{{text}}</div>
      </div>
  </div>
</template>
<script>
export default {
  props: {
      text: {
        type: String,
        default: ''
      },
      textSty: {
        type: String,
        default: ''
      },
      iconSty:{
        type:String,
        default:''
      }
  },
}
</script>
<style scoped lang="scss">
  .no_result{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .center_box{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img_sty{
    width: 160px;
  }
  .text_sty{
    color: #D9D9D9;
    font-size: 18px;
    margin-top: 18px;
  }
</style>
