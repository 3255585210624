import axios from 'axios'
import router from './router'
import store from './store'
import { getCookie } from './assets/js/common'
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true
axios.interceptors.response.use(
  response => {
    if (response.data.code === 520 || response.data.code === 521 || response.data.code === 522 || localStorage.getItem('Authorization') && !getCookie("Authorization")) {
       localStorage.removeItem('Authorization')
       localStorage.removeItem('user')
       router.currentRoute.path !== '/login' && router.replace({ path: '/login', query: { redirect: router.currentRoute.path } })
    } else {
      return response
    }
  }
)
// 添加请求拦截器，在请求头中加token
axios.interceptors.request.use(
  config => {
    if (localStorage.getItem('Authorization')) {
      config.headers.token = localStorage.getItem('Authorization');
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  });
export default axios
