import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
    user: localStorage.getItem('user') ? localStorage.getItem('user') : '',
  },
  mutations: {
    changeLogin (state, user) {
      localStorage.setItem('Authorization', user.Authorization)
      localStorage.setItem('user', user.user)
    },
  },
  actions: {
  },
  modules: {
  }
})
