<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基于突发检测的新兴技术识别" name="first">
        <div v-show="incidentsTechnologyData.data.length">
          <div id="gante" class="gante"></div>
          <el-table
            :data="incidentsTechnologyData.data"
            height="30.7vh"
            stripe
            class="tec_theme"
            style="width: 92%">
            <el-table-column
              type="index"
              label="序号"
              header-align="center"
              align="center"
              width="180">
            </el-table-column>
            <el-table-column
              prop="word"
              label="新兴突发词"
              header-align="left"
              align="left"
              width="180">
              <template slot-scope="scope">
                  <el-tooltip :content="scope.row.word" placement="bottom" effect="light">
                    <span style="white-space: nowrap;">{{scope.row.word}}</span>
                  </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              label=""
              header-align="left"
              align="left"
              width="180">
              <template slot-scope="scope">
                <i class="iconfont icon-liebiao2 active_span" @click="popClickKeyword(scope.row.word)"></i>
              </template>
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center">
              <template slot-scope="scope">
                {{formatDecimal(scope.row.weight,4)}}
              </template>
              <template slot="header" slot-scope="{}">
                <div class="center_sty">
                  <span>权重</span>
                  <el-tooltip content="与突发时长和关键词词频呈正相关关系，权重越大，关键词突发程度越高。" placement="bottom" effect="light">
                    <i class="iconfont icon-wenhao"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="start"
              header-align="center"
              align="center"
              label="突发开始时间">
            </el-table-column>
            <el-table-column
              prop="end"
              header-align="center"
              align="center"
              label="突发结束时间">
            </el-table-column>
            <el-table-column
              prop="length"
              header-align="center"
              align="center"
              label="突发时长">
            </el-table-column>
          </el-table>
          <div :class="['swiper-button-prev', (incidentsTechnologyData.has_last ? '':'swiper-button-disabled')]" @click="prevBtn"></div>
          <div :class="['swiper-button-next',(incidentsTechnologyData.has_next ? '':'swiper-button-disabled')]" @click="nextBtn"></div>
        </div>
        <no-data v-if="!incidentsTechnologyData.data.length && !nodataLoading" text="暂无相关内容" iconSty="margin-top:200px;"></no-data>
        <div v-if="!incidentsTechnologyData.data.length && nodataLoading" v-loading="!incidentsTechnologyData.data.length && nodataLoading" style="width:100px;height:100px;margin: 0 auto;margin-top: 200px;"></div>
      </el-tab-pane>
      <el-tab-pane label="基于指标评估的新兴技术识别" name="second">
        <div id="emergingTechnology">
          <div v-if="!scatterList.length && evaluatNodataLoading" v-loading="!scatterList.length && evaluatNodataLoading" style="width:100px;height:100px;margin: 0 auto;margin-top: 100px;"></div>
        </div>
        <el-table
          :data="targetEvaluateData"
          height="30.7vh"
          stripe
          class="tec_theme"
          style="width: 100%">
          <el-table-column
            type="index"
            label="序号"
            header-align="center"
            align="center"
            width="180">
          </el-table-column>
          <el-table-column
            prop="name"
            label="技术主题"
            header-align="left"
            align="left"
            width="180">
            <template slot-scope="scope">
                <el-tooltip :content="scope.row.name" placement="bottom" effect="light">
                  <span style="white-space: nowrap;">{{scope.row.name}}</span>
                </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label=""
            header-align="left"
            align="left"
            width="100">
            <template slot-scope="scope">
              <i class="iconfont icon-liebiao2 active_span" @click="popClick(scope.row.name,scope.row.topic_id)"></i>
            </template>
          </el-table-column>
          <el-table-column
            prop="year_num"
            header-align="center"
            align="center">
            <template slot-scope="scope">
              {{formatDecimal(scope.row.year_num,4)}}
            </template>
            <template slot="header" slot-scope="{}">
              <div class="center_sty">
                <span>新颖性</span>
                <el-tooltip content="当前技术主题所对应科技文献成果的时效性。数值越大，新颖性越强。" placement="bottom" effect="light">
                  <i class="iconfont icon-wenhao"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="ris_num"
            header-align="center"
            align="center">
            <template slot-scope="scope">
              {{formatDecimal(scope.row.ris_num,4)}}
            </template>
            <template slot="header" slot-scope="{}">
              <div class="center_sty">
                <span>增长性</span>
                <el-tooltip content="当前技术主题所对应科技文献数量的增长率。数值越大，增长性越强。" placement="bottom" effect="light">
                  <i class="iconfont icon-wenhao"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="ori_num"
            header-align="center"
            align="center">
            <template slot-scope="scope">
              {{formatDecimal(scope.row.ori_num,4)}}
            </template>
            <template slot="header" slot-scope="{}">
              <div class="center_sty">
                <span>原创性</span>
                <el-tooltip content="当前技术主题与其他技术主题在研究内容上的差异性。数值越大，原创性越强。" placement="bottom" effect="light">
                  <i class="iconfont icon-wenhao"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="rate"
            header-align="center"
            align="center">
            <template slot-scope="scope">
              {{formatDecimal(scope.row.rate,4)}}
            </template>
            <template slot="header" slot-scope="{}">
              <div class="center_sty">
                <span>新兴程度得分</span>
                <el-tooltip content="新颖性、增长性和原创性数值分别取1/3权重，并加权计算得到的分值。分值越大，新兴程度越高。" placement="bottom" effect="light">
                  <i class="iconfont icon-wenhao"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

    </el-tabs>
    <el-dialog :title="xiazuanName" :visible.sync="dialogTableVisible" class="xiazuan_dialog">
        <el-table :data="xiazuanData" height="500" stripe class="dialog_table" v-loading="loadingXiazuan">
          <el-table-column type="index" label="序号" width="60" header-align="center" align="center"></el-table-column>
          <el-table-column label="标题" width="200" header-align="center" align="center">
            <template slot-scope="scope">
              <el-popover
                placement="left"
                trigger="click"
                :content="scope.row[0]"
                >
                <span slot="reference" style="white-space: nowrap;" class="active_span">{{scope.row[0]}}</span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="摘要" header-align="center" align="center">
            <template slot-scope="scope">
              <el-popover
                placement="left"
                trigger="click"
                :content="scope.row[1]"
                >
                <span slot="reference" style="white-space: nowrap;" class="active_span">{{scope.row[1]}}</span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="关键词" header-align="center" align="center">
            <template slot-scope="scope">
                <el-popover
                placement="left"
                trigger="click"
                :content="scope.row[2]"
                >
                <span slot="reference" style="white-space: nowrap;" class="active_span">{{scope.row[2]}}</span>
              </el-popover>
            </template>
          </el-table-column>

        </el-table>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from 'echarts';
import 'echarts-gl';
import "swiper/dist/css/swiper.css";
import HighCharts from 'highcharts'
import gantt from 'highcharts/modules/gantt'
import { formatDecimal } from '../plugins/common'
import noData from "../components/noData.vue";
gantt(HighCharts)
export default {
  components: {
    noData,
  },
  data () {
    return {
      dialogTableVisible: false,
      activeName: 'first',
      pageSize: 10, //每页数量
      pageNo: 1, //第几页
      incidentsTechnologyData: {
        data: []
      }, //基于突发检测的新兴技术识别
      scatterList: {},
      targetEvaluateData: [], //指标评估列表数据
      xiazuanName: "",
      xiazuanData: [],
      loadingXiazuan: false,
      nodataLoading: false,
      evaluatNodataLoading: false
    }
  },
  created () {
    this.getIncidentsTechnologyData()
    this.getScatterInfo()
    this.getTargetEvaluateData()
  },

  methods: {
    // 小数点保留
    formatDecimal(num, decimal) {
        num = num.toString()
        let index = num.indexOf('.')
        if (index !== -1) {
            num = num.substring(0, decimal + index + 1)
        } else {
            num = num.substring(0)
        }
        return parseFloat(num).toFixed(decimal)
    },
    handleClick(tab, event) {
      if(tab.index == 1) {
          setTimeout(() => {
            this.drawEmergingTechnology("emergingTechnology",this.scatterList)
          }, 500);
      }

    },
    // 基于突发检测的新兴技术识别列表 数据
    getIncidentsTechnologyData() {
      this.nodataLoading = true
      this.$http({
        method: "GET",
        url: "/emerging_tech_topic/burst/",
        params: {
          taskid: this.$route.query.id,
          pageSize: this.pageSize,
          pageNo: this.pageNo
        }
      }).then(res => {
        if (res.data.code === 0) {
          this.nodataLoading = false
          this.incidentsTechnologyData = res.data
          res.data.data.length ? this.drawGante("gante",res.data.data):''
        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error));
    },

    // 散点图数据
    getScatterInfo() {
      this.evaluatNodataLoading = true
      this.$http({
        method: "GET",
        url: "/emerging_tech_topic/norm/",
        params: {
          taskid: this.$route.query.id
        }
      }).then(res => {
        if (res.data.code === 0) {
          this.evaluatNodataLoading = false
          this.scatterList = res.data
        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error));
    },

    // 指标评估列表 数据
    getTargetEvaluateData() {
      this.$http({
        method: "GET",
        url: "/emerging_tech_topic/norm/list/",
        params: {
          taskid: this.$route.query.id
        }
      }).then(res => {
        if (res.data.code === 0) {
          this.targetEvaluateData = res.data.data
        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error));
    },

    // 甘特图点击 下钻
    getDetailData (type,value) {
      this.xiazuanData = [];
      this.loadingXiazuan = true
      let obj = {
          taskid: this.$route.query.id,
          fields: 'title,abstract,author_keyword',
          pageSize: -1
      }
      type == 'word'? obj.keyword = value: obj.topicid = value
      this.$http({
        method: "POST",
        url: "/topic/post/list/",
        params: obj
      }).then(res => {
        if (res.data.code === 0) {
         this.xiazuanData = res.data.data
         this.loadingXiazuan = false
        }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              duration: 0,
              type: 'error'
            })
        }
      }).catch(error => console.log(error));
    },
    popClickKeyword(word){
      this.dialogTableVisible = true
      this.xiazuanName = word
      this.getDetailData ('word',word)
    },
    popClick (name,id) {
      this.dialogTableVisible = true
      this.xiazuanName = name
      this.getDetailData ('id',id)
    },
    drawEmergingTechnology (el,data) {
      let myChart = echarts.init(document.getElementById(el));
      window.onresize = echarts.init(document.getElementById(el)).resize
      // console.log(data.data[0])
      var option;
      var symbolSize = 12;
      option = {
        grid3D: {},
        xAxis3D: {
          // type: 'category'
          name: Object.values(data.dimensions)[0]
        },
        yAxis3D: {
          name: Object.values(data.dimensions)[1]
        },
        zAxis3D: {
          name: Object.values(data.dimensions)[2]
        },
        tooltip: {
            trigger: 'item',
            formatter: function(prams,d,callback){
              // console.log(prams)
                return '<p style="text-align:left;color:#53689A;"><span>主题名：'+prams.value[3]+'</span><br/><span>新颖性：'+formatDecimal(prams.value[0],4)+'</span><br/><span>增长性：'+formatDecimal(prams.value[1],4)+'</span><br/><span>原创性：'+formatDecimal(prams.value[2],4)+'</span><br/><span>新兴得分程度：'+formatDecimal(prams.value[5],4)+'</span></p>'
            },
        },
        dataset: {
          dimensions:data.data[0],
        //  dimensions: ['新颖性', '增长性', '原创性', '主题名', '主题ID', '得分'],
          source: data.data
        },
        series: [
          {
            name: 'scatter3D',
            type: 'scatter3D',
            data: data.data,
            symbolSize: symbolSize,
            encode: {
              // x: Object.values(data.dimensions)[0],
              // y: Object.values(data.dimensions)[1],
              // z: Object.values(data.dimensions)[2],
              x: 0,
              y: 1,
              z: 2,
              tooltip: [0, 1, 2, 3, 4, 5],
              itemId: 4,
              itemName: 3,
            },
            dimensions: [
              Object.values(data.dimensions)[0],
              Object.values(data.dimensions)[1],
              Object.values(data.dimensions)[2],
            ],

            emphasis:{
              label: false
            }
          }
        ]
      };
      document.getElementById(el).removeAttribute('_echarts_instance_');
      option && myChart.setOption(option);
      // return myChart;
    },
    drawGante (el,data) {
      // console.log(data.map((item)=>item.word))
      let timeData = []
      let that = this
      for(let i = 0; i < data.length; i++){
        timeData.push({
          x: Date.UTC(data[i].start),
          x2: Date.UTC(data[i].end +1),
          y: i,
        })
      }
        HighCharts.chart(el,
            {
          colors: ['#DB7070','#EBAF8F','#DD8941','#EBD000','#A0D15D','#72D16E','#31C5CB', '#62ADDB','#62ADDB', '#47C3B0'],
          chart: {
            type: 'xrange',
            marginRight: 30
          },
          title: {
            text: ''
          },
          credits: {
            enabled:false
          },//去掉地址
          xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
              // week: '%Y/%m/%d'
              // year: '%Y',
              // month: '%B \'%y',
            }
          },
          yAxis: {
            title: {
              text: ''
            },
            categories: data.map((item)=>item.word),
            reversed: true,
          },
          plotOptions: {
                series: {
                    cursor: 'pointer',
                    events: {
                        click: function (event) {
                          // that.getDetailData (event.point.yCategory)
                          // that.xiazuanName = event.point.yCategory
                          // that.dialogTableVisible = true
                        }
                    }
                }
            },
          tooltip: {
            enabled: false
          },
          series: [{
            name: '',
            showInLegend: false,
            pointWidth: 20,
            data: timeData,
            dataLabels: {
              enabled: true
            },
          }]
        });
    },
    prevBtn () {
      this.pageNo -= 1
      this.getIncidentsTechnologyData()
    },
    nextBtn () {
      this.pageNo += 1
      this.getIncidentsTechnologyData()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/scss/mixin.scss";
.el-tabs{
  margin-top: -10px;
  /deep/.el-tabs__nav-wrap{
    .el-tabs__active-bar{
      height: 5px;
      background-color: $darkblue;
    }
    .el-tabs__item{
      font-weight: bold;
      &.is-active{
        color: $darkblue;
      }
      &:hover{
        color: $darkblue;
      }
    }
    &::after{
      height: 1px;
      background-color: $middleblue
    }
  }
}
.gante{
  width: 92%;
  // height: 400px;
  height: 42.3vh;
  border: 1px solid #E6E9F3;
  margin: 0 auto;
  margin-bottom: 11px;
  text-align: center;
}
#emergingTechnology{
  width: 100%;
  // height: 400px;
  height: 42.3vh;
  border: 1px solid #E6E9F3;
  margin-bottom: 11px;
  text-align: center;
}
.active_span{
  cursor: pointer;
  &:focus,&:active,&:hover{
    color: #3a8ee6;
  }
}
.el-table.tec_theme{
  margin: 0 auto;
  /deep/.el-table__body-wrapper{
    .el-table__body{
      width: 100% !important;
    }
  }
}
.el-table.pop_table{
  margin: 0 18px;
  box-sizing: border-box;
  width: auto;
  /deep/.el-table__row{
    .cell{
      white-space: nowrap;
    }
  }
}
  .swiper-button-prev{
    background: url("../assets/images/swiper_l_d.png") no-repeat center center/100% 100%;
  }
  .swiper-button-next{
    background: url("../assets/images/swiper_r_d.png") no-repeat center center/100% 100%;
  }
  .xiazuan_dialog{
    /deep/.el-dialog__body{
        padding: 10px;
      }
    /deep/.el-dialog__header{
      padding: 13px;
      background-color: #FAFAFA;

      .el-dialog__title{
        color: $black666;
        font-size: 14px;
        line-height: 14px;
      }
      .el-dialog__headerbtn{
        top: 13px;
      }
    }
    .active_span{
      cursor: pointer;
      &:focus,&:active,&:hover{
        color: #3a8ee6;
      }
    }
  }
  .el-table.dialog_table{
    /deep/.el-table__body-wrapper{
      .el-table__body{
        width: 100% !important;
      }
    }
  }
</style>
<style lang="scss">
  // .el-popover.pop_theme{
  //   padding: 0;
  //   padding-bottom: 18px;
  //   .el-popover__title{
  //     padding-left: 20px;
  //     height: 40px;
  //     line-height: 40px;
  //     background-color: #FAFAFA;
  //   }
  // }
</style>
