<template>
  <div class="login-body">
    <div class="login_top f20 bold">技术主题识别和发展预测</div>
    <div class="login_center">
      <div class="form_body">
        <div class="form-input">
            <p class="title f24">用户登录</p>
            <div class="input-item flex border_sty" style="margin-bottom:30px;">
              <span class="input-label f18">账 号</span>
              <span class="input-style"><el-input class="f18" v-model="username" @focus="inputFocus" @keyup.enter.native="login()"></el-input></span>
            </div>
            <div class="input-item flex border_sty">
              <span class="input-label f18">密 码</span>
              <span class="input-style"><el-input type="password" class="f18" v-model="password" @focus="inputFocus" @keyup.enter.native="login()"></el-input></span>
            </div>
            <div class="input-item" style="margin-top:53px;">
              <div class="login-btn f14 bold" @click="login()">登录</div>
            </div>
        </div>
      </div>
    </div>
    <copyright></copyright>
  </div>
</template>
<style lang="scss">
@import "../assets/scss/mixin.scss";
.login-body{
  height: 100vh;
  width: 100%;
  background: $darkblue;
  .login_top{
    height: 80px;
    line-height: 80px;
    color: $white;
    margin-left: 63px;
    @include letter-spacing(1px);
  }
  .login_center{
    width: 100%;
    height: calc(100vh - 80px - 30px);
    background: url('../assets/images/login_bg.png') no-repeat center center/100% 100%;
    .form_body{
      width: 538px;
      height: 441px;
      border: 2px solid $white;
      @include border-radius(5px);
      background: $white2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      .form-input{
        text-align: center;
        color: $white;
        margin-top: 52px;
        .input-item{
          width: 63%;
          margin: 0 auto;
          .input-label{
            width: 50px;
          }
          .input-style{
            flex-grow: 1;
            input{
              width: 100%;
              background: transparent;
              box-shadow: inherit;
              border: none;
              outline: none;
              height: 26px;
              line-height: 26px;
              padding: 0 3px;
              color: $white;
              &:-webkit-autofill{
                -webkit-text-fill-color: $white !important;
                -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;
                background-color:transparent;
                background-image: none;
                transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
              }
            }
          }
        }
        .border_sty{
          border-bottom: 1px solid $white;
          padding-bottom: 10px;
        }
        .title{
          margin-bottom: 68px;
        }
        .login-btn{
          background-color: $white4;
          border: 1px solid $white;
          @include border-radius(2px);
          @include letter-spacing(16px);
          padding:12px 0;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<script>
import md5 from 'js-md5';
import { mapMutations } from 'vuex';
import copyright from '../components/copyright.vue'
import { setCookie } from '../assets/js/common'
export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      submit_flag: true,
      form_submit: false,
    }
  },
  components:{
    copyright
  },
  created () {
  },
  methods: {
    ...mapMutations(['changeLogin']),
    inputFocus () {
      this.form_submit = false
    },
    login () {
      this.form_submit = false
      if (!this.username) {
        this.form_submit = true
        this.$toast({
          text: '请输入账号',
          duration: 2000
        })
        return false
      }
      if (!this.password) {
        this.form_submit = true
        this.$toast({
          text: '请输入密码',
          duration: 2000
        })
        return false
      }
      if (this.submit_flag) {
        this.submit_flag = false
        var postData = {
          name: this.username,
          // password: md5(this.password),
          password: this.password,
        }
        postData = this.$qs.stringify(postData)
        this.$http({
          method: 'POST',
          url: '/account/login/',
          data: postData
        }).then(res => {
          if (res.data.code === 0) {
            const user = res.data.name
            const token = res.data.token
            // 将用户token保存到vuex中
            this.changeLogin({ Authorization: token,user: user});
            setCookie("Authorization", res.data.token,'.maixunbytes.com')
            setCookie("Name", res.data.name,'.maixunbytes.com')
            // this.$store.commit('updateUser', user)
            this.$router.push('/')
          } else {
            this.$toast({
              text: res.data.msg,
              duration: 2000
            })
          }
          this.form_submit = true
          this.submit_flag = true
        }).catch(() => {
          this.form_submit = true
          this.submit_flag = true
        })
      }
    },
  }
}
</script>
